<template>
	<v-menu
		content-class="elevation-0 border-light-grey"
		v-model="particularField.fieldActive"
		right
		bottom
		tile
		offset-x
		:close-on-content-click="false"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-icon
				small
				color="blue darken-4"
				class="btx-small-edit"
				v-bind="attrs"
				v-on="on"
				v-on:click="updateField()"
				>mdi-pencil</v-icon
			>
		</template>
		<v-card depressed tile>
			<v-form
				ref="particularForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="saveField"
			>
				<v-card-text class="pb-2 pt-1 small-fields max-width-300px">
					<PhoneInput
						hide-details
						:loading="formLoading"
						:disabled="formLoading"
						ref="field"
						v-model="particularField.field"
						:rules="[vrules.required(particularField.field)]"
					></PhoneInput>
				</v-card-text>
			</v-form>
			<v-card-actions class="px-4 pt-0">
				<v-spacer></v-spacer>
				<v-btn
					class="white--text"
					depressed
					color="blue darken-4"
					tile
					small
					small-compressed
					v-on:click="saveField"
					:disabled="formLoading || !formValid"
				>
					Save
				</v-btn>
				<v-btn
					depressed
					:disabled="formLoading"
					tile
					small
					v-on:click="closeParticular"
					small-compressed
				>
					Close
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>
<script>
import { isEmpty } from "lodash";
import PhoneInput from "@/view/components/Phone";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";

export default {
	name: "phone-input-internal",
	title: "Phone Input Internal",
	model: {
		prop: "value",
		event: "input",
	},
	data() {
		return {
			formValid: true,
			formLoading: false,
			particularField: new Object(),
		};
	},
	components: {
		PhoneInput,
	},
	props: {
		field: {
			type: String,
			default: null,
		},
		uuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		value: {
			type: String,
			default: null,
		},
	},
	methods: {
		updateField() {
			const _this = this;
			_this.particularField = new Object({
				fieldActive: true,
				field: _this.value,
			});
			_this.$nextTick(() => {
				const { field } = _this.$refs;
				if (field && !isEmpty(field.$refs)) {
					const { phoneInput } = field.$refs;
					if (phoneInput && !isEmpty(phoneInput.$refs)) {
						const { input } = phoneInput.$refs;
						if (input) {
							setTimeout(() => {
								input.focus();
							}, 50);
						}
					}
				}
			});
		},
		closeParticular() {
			this.particularField = new Object();
			this.$emit("close", true);
		},
		saveField() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.particularForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.particularForm.validate()) {
				return false;
			}

			_this.formLoading = true;

			ApiService.setHeader();
			ApiService.patch(_this.endpoint, {
				[_this.field]: _this.particularField.field,
				uuid: _this.uuid,
			})
				.then(() => {
					_this.$emit("success", true);
					_this.closeParticular();
				})
				.catch(({ response }) => {
					_this.$store.commit(SET_ERROR, [{ model: true, message: response }]);
				})
				.finally(() => {
					_this.formLoading = false;
				});
		},
	},
};
</script>
