<template>
	<Dialog :dialog="serviceDialog" :dialog-width="dialogWidth">
		<template v-slot:title>Extend {{ type }} Service</template>
		<template v-slot:body>
			<v-form
				ref="extendServiceForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row v-if="serviceDialog" class="service-dialog">
					<v-col md="4" class="my-auto py-0">
						<label :for="`expected-completion-${uniqFieldId}`" class="btx-label mt-3 required"
							>Expected Completion</label
						>
					</v-col>
					<v-col md="8" class="py-0">
						<DateTimePicker
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							:min-date="today"
							:id="`expected-completion-${uniqFieldId}`"
							placeholder="Expected Completion"
							v-model="service.expected_end_date"
							required
						></DateTimePicker>
					</v-col>
					<v-col md="4" class="py-0"> </v-col>
					<v-col md="8" class="py-0">
						<v-checkbox
							dense
							hide-details
							label="Under service Indefinitely"
							class="mt-3 mb-0 p-0"
							:true-value="1"
							:false-value="0"
							v-model="service.indefinitely"
						></v-checkbox>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				v-on:click="onSubmit()"
			>
				Save
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import DateTimePicker from "@/view/components/DateTimePicker";

export default {
	name: "extend-service-template",
	title: "Extend Service Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		serviceUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		serviceDialog: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		serviceDialog(param) {
			if (param) {
				this.init();
			}
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			service: {
				expected_end_date: null,
				indefinitely: 0,
			},
		};
	},
	components: {
		Dialog,
		DateTimePicker,
	},
	methods: {
		init() {
			this.service = new Object({
				expected_end_date: null,
				indefinitely: 0,
			});
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.extendServiceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.extendServiceForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.patch(
				`${this.endpoint}/${this.typeUuid}/service/${this.serviceUuid}/extend`,
				this.service
			)
				.then(() => {
					this.$emit("success");
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
