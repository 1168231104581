<template>
	<Dialog :dialog="serviceDialog" :dialog-width="dialogWidth">
		<template v-slot:title>Complete {{ type }} Service</template>
		<template v-slot:body>
			<v-form
				ref="completeServiceForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row v-if="serviceDialog" class="service-dialog">
					<v-col md="4" class="my-auto py-0">
						<label :for="`service-cost-${uniqFieldId}`" class="btx-label mt-3 required">Cost</label>
					</v-col>
					<v-col md="8" class="py-0">
						<PriceInput
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							:id="`service-cost-${uniqFieldId}`"
							placeholder="Cost"
							v-model="service.cost"
							:rules="[vrules.required(service.cost, 'Cost')]"
							:class="{
								required: !service.cost,
							}"
						></PriceInput>
					</v-col>
					<v-col md="4" class="py-0">
						<label :for="`description-${uniqFieldId}`" class="btx-label mt-3">Description</label>
					</v-col>
					<v-col md="8" class="py-0">
						<TextAreaInput
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							:id="`description-${uniqFieldId}`"
							placeholder="Description"
							v-model="service.description"
						></TextAreaInput>
					</v-col>
					<template v-if="false">
						<v-col md="12" class="font-level-3-bold pb-3 border-bottom-light-grey text-black mt-4 py-0">
							Linked Perishables Items <br />
							<span class="font-level-0 text-muted">
								<v-icon small>mdi-progress-question</v-icon> The Perishables Items consumed during
								maintenance</span
							>
						</v-col>
						<v-col md="12" class="py-0">
							<LinkAssetInventoryItem v-model="service.inventories"></LinkAssetInventoryItem>
						</v-col>
					</template>
					<v-col md="12" class="font-level-3-bold pb-3 border-bottom-light-grey text-black mt-4 py-0">
						Attachments
					</v-col>
					<v-col md="12" class="py-0 mt-4">
						<UploadFile v-on:files="manageFiles"> </UploadFile>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				v-on:click="onSubmit()"
			>
				Save
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import PriceInput from "@/view/components/PriceInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import UploadFile from "@/view/components/UploadFile";
import LinkAssetInventoryItem from "@/view/components/LinkAssetInventoryItem";
import { EventBus } from "@/core/event-bus/event.bus";
import { map } from "lodash";

export default {
	name: "complete-service-template",
	title: "Complete Service Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		serviceUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		serviceDialog: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		serviceDialog(param) {
			if (param) {
				this.init();
			}
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			service: {
				cost: null,
				inventories: [],
				attachments: [],
				description: null,
			},
		};
	},
	components: {
		Dialog,
		UploadFile,
		PriceInput,
		TextAreaInput,
		LinkAssetInventoryItem,
	},
	methods: {
		init() {
			this.service = new Object({
				cost: null,
				inventories: [],
				attachments: [],
				description: null,
			});
		},
		manageFiles(data) {
			if (data) {
				this.service.attachments = map(data, (row) => {
					return row.url;
				});
			}
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.completeServiceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.completeServiceForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.patch(
				`${this.endpoint}/${this.typeUuid}/service/${this.serviceUuid}/complete`,
				this.service
			)
				.then(() => {
					EventBus.$emit("reload:files", true);
					this.$emit("success");
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
