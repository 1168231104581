<template>
	<Dialog :dialog="deactivateDialog" :dialog-width="600">
		<template v-slot:title> De-Activate {{ type }}</template>
		<template v-slot:body>
			<v-row class="delete-dialog">
				<v-col md="2" class="py-0 text-right my-auto">
					<span class="svg-icon svg-icon-lg delete-confirm-icon">
						<!--begin::Svg Icon-->
						<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
						<!--end::Svg Icon-->
					</span>
				</v-col>
				<v-col md="10" class="py-0 my-auto">
					<p class="btx-p m-0">
						Are you sure about de-activating {{ type }}
						<span class="red--text lighten-1 font-level-1-bold"> {{ deactivateText }}</span
						>?
					</p>
				</v-col>
				<v-col v-if="deactivateNote" md="12" class="pb-0 my-auto">
					<em>Note: {{ deactivateNote }}</em>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="deactivateButton"
				:disabled="deactivateButton"
				depressed
				color="red lighten-1"
				tile
				v-on:click="deactivateRow()"
			>
				Yes! De-Activate
			</v-btn>
			<v-btn depressed tile :disabled="deactivateButton" v-on:click="$emit('close', true)">
				No, Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	name: "deactivate-template",
	title: "De-Activate Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		deactivateUrl: {
			type: String,
			default: null,
		},
		deactivateText: {
			type: String,
			default: null,
		},
		deactivateNote: {
			type: String,
			default: null,
		},
		deactivateDialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			deactivateButton: false,
		};
	},
	components: {
		Dialog,
	},
	methods: {
		deactivateRow() {
			this.deactivateButton = true;
			ApiService.patch(this.deactivateUrl)
				.then(() => {
					this.$emit("success");
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! ${this.type} has been de-activated.` },
					]);
					this.$store.dispatch(SET_LOCAL_DB);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.deactivateButton = false;
				});
		},
	},
};
</script>
